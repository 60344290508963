body > nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  min-width: 50px;
  background-color: #376891;
}
body > nav button {
  width: 50px;
  height: 60px;
  border-color: #376891;
  border-style: solid;
  border-width: 14px;
  border-left-width: 9px;
  border-right-width: 9px;
  background-color: #376891;
  background-repeat: no-repeat;
  background-size: 32px auto !important;
  outline: none;
}
body > nav button:hover,
body > nav button.active {
  background-color: #5791c1;
  border-color: #5791c1;
}
body > nav button.active {
  background-color: #274a66;
  border-color: #274a66;
}
body > nav hr {
  width: 50px;
  height: 1px;
  min-height: 1px;
  background: #7397b4;
  border: none;
  margin: 0;
}
body > nav header,
body > nav footer {
  width: 50px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}
.w2ui-field > label {
  width: 250px!important;
  white-space: normal!important;
}
.w2ui-field > div {
  margin-left: 258px!important;
  color: #666;
}
.w2ui-field > div > article {
  margin-top: 4px;
  display: inline-block;
}
.w2ui-page header {
  font-family: Arial Narrow;
  font-size: 26px;
  color: #332;
}
.w2ui-page header:last-child {
  margin-bottom: -10px;
}
.w2ui-grid .w2ui-grid-body .w2ui-grid-records table tr.status_notice td[data-status] {
  background-color: #eeeeff;
}
.w2ui-grid .w2ui-grid-body .w2ui-grid-records table tr.status_created td[data-status] {
  background-color: #ddffdd;
}
.w2ui-grid .w2ui-grid-body .w2ui-grid-records table tr.status_fail td[data-status] {
  background-color: #ffcccc;
}
.w2ui-grid .w2ui-grid-body .w2ui-grid-records table tr.status_warning td[data-status] {
  background-color: #ffff99;
}
.w2ui-grid .w2ui-grid-body .w2ui-grid-records table tr.status_deleted td[data-status] {
  background-color: #ffcccc;
  text-decoration: line-through;
}
.w2ui-grid .w2ui-grid-body table .w2ui-head > div {
  white-space: normal;
  text-align: center;
}
#grid_reportGrid_fcolumns tbody tr:nth-child(2) td,
#grid_reportGrid_columns tbody tr:nth-child(2) td {
  height: 25px;
}
#grid_reportGrid_fcolumns tbody tr:nth-child(2) td[rowspan="2"],
#grid_reportGrid_columns tbody tr:nth-child(2) td[rowspan="2"] {
  height: 117px;
}
div.w2ui-tabs div.w2ui-tab.active {
  background-color: #eef5eb;
  font-weight: bold;
}
.w2ui-grid-advanced-search {
  padding: 8px;
}
.w2ui-grid-advanced-search input {
  display: inline-block;
  float: left;
  margin-right: 5px;
}
.w2ui-grid-advanced-search label {
  font-size: 14px;
}
#w2ui-field-voc-page {
  padding: 10px;
  border: 1px solid silver;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-top: 7px;
}
#w2ui-field-voc-page-buttons {
  padding: 15px 0;
  background-color: #fafafa;
  border: 1px solid silver;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  text-align: center;
}
#w2ui-field-voc-select-grid + div {
  padding: 10px 0;
}
.svg-help {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 0;
}
.svg-help-dims {
  width: 34px;
  height: 34px;
}
.svg-keys {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 20%;
}
.svg-keys-dims {
  width: 34px;
  height: 34px;
}
.svg-logout {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 40%;
}
.svg-logout-dims {
  width: 34px;
  height: 34px;
}
.svg-menu {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 60%;
}
.svg-menu-dims {
  width: 34px;
  height: 34px;
}
.svg-settings {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 80%;
}
.svg-settings-dims {
  width: 34px;
  height: 34px;
}
.svg-users {
  background: url(/assets/sprite.9a746aa5.svg) no-repeat;
  background-position: 0 100%;
}
.svg-users-dims {
  width: 34px;
  height: 34px;
}
.invisible {
  display: none;
}
.clickable {
  cursor: pointer;
}
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Arial, sans-serif;
  font-size: 11px;
}
body input:not([type=button]):not([type=submit]).w2ui-input:disabled,
body textarea.w2ui-input:disabled,
body input:not([type=button]):not([type=submit]).w2ui-input[readonly],
body textarea.w2ui-input[readonly] {
  background-color: white;
}
body > main {
  flex: 1;
  flex-direction: column;
  margin: 0 0 0 50px;
}
body > main header {
  margin-bottom: 0!important;
}
body > main article {
  flex: 1;
}
span.anchor {
  cursor: pointer;
}
@font-face {
  font-family: 'Glyphicons Halflings';
  src: url(/assets/glyphicons-halflings-regular.448c34a5.woff2) format('woff2');
}
.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.glyphicon-plus:before {
  content: "\2b";
}
.glyphicon-minus:before {
  content: "\2212";
}
.glyphicon-book:before {
  content: "\e043";
}
.w2ui-btn.multi {
  position: relative;
  padding-right: 25px;
}
.w2ui-btn.multi:after {
  content: "";
  border: 5px solid transparent;
  border-top: 5px solid #fff;
  position: absolute;
  top: 13px;
  right: 9px;
}
.w2ui-btn.multi.show {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.w2ui-btn.multi.show:after {
  border: 5px solid transparent;
  border-bottom: 5px solid #fff;
  top: 8px;
}
.w2ui-multi-btn-wrapper {
  position: absolute;
  z-index: 9999;
  display: none;
  background-color: #fff;
}
.w2ui-multi-btn-wrapper.show {
  display: block;
}
.w2ui-multi-btn-wrapper .w2ui-btn {
  display: block;
  width: 100%;
  margin: 0;
  border: 0!important;
  border-radius: 0;
  box-shadow: none;
  text-align: left;
}
.w2ui-multi-btn-wrapper .w2ui-btn:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}
.w2ui-multi-btn-wrapper .w2ui-btn:first-child {
  border-top-right-radius: 4px;
}
.w2ui-multi-btn-wrapper .w2ui-btn:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.histogram_tooltip {
  position: absolute;
  text-align: center;
  min-width: 80px;
  height: 28px;
  line-height: 28px;
  padding: 0 4px;
  font-size: 12px;
  background: #fff;
  border: 1px solid lightsteelblue;
  pointer-events: none;
  vertical-align: middle;
  text-align: left;
}
